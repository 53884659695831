import { useEffect, useRef } from "react";

import type { Fetcher } from "@remix-run/react";
import { useFetchers } from "@remix-run/react";

// this is to be deprecated in favor of useFetcher persist & key https://github.com/remix-run/remix/pull/7704
export const useOnCompleted = <T = unknown>(
  onCompleted: (
    data: Fetcher<T>["data"],
    submitFetcher: Fetcher<T> | null,
  ) => void,
  fetcher: Fetcher<T> | undefined,
) => {
  const refIsStarted = useRef<boolean | null>(null);
  const refData = useRef<T | null>(null);
  const refPayload = useRef<any>(null); // gave up on typing this

  useEffect(() => {
    if (!fetcher) return;
    if (fetcher.state === "loading") {
      refData.current = fetcher.data || null;
    }
    if (fetcher.state === "idle" && refIsStarted.current) {
      tryCatch(() =>
        onCompleted(
          fetcher.data || refData.current || undefined,
          refPayload.current,
        ),
      );
      refIsStarted.current = null;
      refData.current = null;
      refPayload.current = null;
    }
    if (fetcher.state === "submitting") {
      refPayload.current = fetcher;
      refIsStarted.current = true;
    }
  }, [fetcher, onCompleted]);
};

export const useOnActionCompleted = <T = unknown, S extends string = string>(
  action: S,
  onCompleted: (
    data: Fetcher<T>["data"],
    submitFetcher: Fetcher<T> | null,
  ) => void,
) => {
  const fetchers = useFetchers();
  const active = fetchers.find(
    fetcher => fetcher.state !== "idle" && fetcher.formAction?.includes(action),
  );

  useOnCompleted<Fetcher<T>["data"]>(
    onCompleted,
    active || (IdleFetcher as Fetcher<T>),
  );
};

const IdleFetcher: Fetcher = {
  state: "idle",
  data: undefined,
  formAction: undefined,
  formMethod: undefined,
  formEncType: undefined,
  text: undefined,
  formData: undefined,
  json: undefined,
};
const tryCatch = (cb: () => void) => {
  try {
    cb();
  } catch (e) {
    console.error(e);
  }
};
